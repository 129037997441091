.container {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

/*
  Phone portrait
*/
@media (max-width: 479px) {
  .container {
    width: 100%;
  }
  :global(.button) {
    width: 100%
  }
}