.container {
  padding: 13px var(--dimension-padding-medium) 0 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30%;
}
.container.tipped {
  padding-top: 3px
}

.label {
  font-weight: 400;
}
.tip {
  font-weight: 400;
  font-size: var(--font-size-small);
  color: var(--color-muted);
}

/*
  Phone portrait
*/
@media (max-width: 479px) {
  .container {
    width: 100%;
    margin-bottom: 6px;
    flex-grow: 1;
    flex-basis: 0;
  }
}