.container {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.inner {
  width: 100%;
  max-width: 600px;
  padding: 0 var(--dimension-padding-default);
  text-align: center;
}
.inner h1 {
  margin: 0 0 var(--dimension-padding-default);
  font-size: var(--font-size-large);
  font-weight: 300
}