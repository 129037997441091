.container {
  width: 30px;
  height: 30px;
  margin: 0 6px 3px 0;
  border: solid 1px rgba(0,0,0,.1);
  background-color: #fff;
  background-position: 50% 50%;
  background-size: 80% auto;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
}
.container::after {
  padding: 4px 12px;
  background: #fff;
  font-size: 12px;
  white-space: nowrap;
  left: 0;
  top: 28px;
  position: absolute;
  z-index: 10;
  display: none;
}
.container:hover::after {
  display: block
}

.local {
  background-image: url(../../img/email.svg);
}
.local:after {
  content: "Email & password"
}
.google-oauth2 {
  background-image: url(../../img/google.svg);
}
.google-oauth2:after {
  content: "Google"
}
.linkedin {
  background-image: url(../../img/linkedin.svg);
}
.linkedin:after {
  content: "LinkedIn"
}
.adfs {
  background-image: url(../../img/microsoft.svg);
}
.adfs:after {
  content: "ADFS"
}
.ms-teams {
  background-image: url(../../img/msteams.svg);
}
.ms-teams:after {
  content: "MS Teams"
}
.waad {
  background-image: url(../../img/waad.svg);
}
.waad:after {
  content: "Windows Azure Active Directory"
}
.samlp {
  background-image: url(../../img/sso.svg);
}
.samlp:after {
  content: "SAMLP"
}

/* .icon.local {
  background-image: url(../../img/email.svg);
}
.icon.local::after {
  content: "Local (email & password)";
}

.icon.google-oauth2 {
  background-image: url(../../img/google.svg);
}
.icon.google-oauth2::after {
  content: "Google";
}

.icon.linkedin {
  background-image: url(../../img/linkedin.svg);
}
.icon.linkedin::after {
  content: "LinkedIn";
}

.icon.adfs {
  background-image: url(../../img/microsoft.svg);
}
.icon.adfs::after {
  content: "ADFS";
}

.icon.ms-teams {
  background-image: url(../../img/msteams.svg);
}
.icon.ms-teams::after {
  content: "MS Teams";
}

.icon.waad {
  background-image: url(../../img/microsoft.svg);
}
.icon.waad::after {
  content: "Windows Azure Active Directory";
}

.icon.samlp {
  background-image: url(../../img/sso.svg);
}
.icon.samlp::after {
  content: "SAMLP";
}
 */