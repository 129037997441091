.container {
  width: 100%;
  padding: var(--dimension-padding-medium);
  border-bottom: solid 1px rgba(0,0,0,.06);
  display: flex
}
.container:last-child {
  border-bottom: 0;
}

.container.slim {
  padding-top: 0;
  padding-bottom: 0;
}
.container.slim:last-child {
  padding-bottom: calc(var(--dimension-padding-medium) / 2);
}

/*
  Phone portrait
*/
@media (max-width: 479px) {
  .container {
    flex-direction: column;
  }
}