.container {
  align-items: center;
  display: flex;
}
.container :global(.icon) {
  color: var(--color-black-brand);
}

.prev, .next {
  transition: opacity .1s linear;
  opacity: .5;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
  display: block;
  cursor: pointer;
  user-select: none
}
.prev:hover, .next:hover {
  opacity: 1;
}
.pages {
  color: var(--color-muted);
  text-align: center;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}