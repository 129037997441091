.container {
  padding-left: 30%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

/*
  Phone portrait
*/
@media (max-width: 479px) {
  .container {
    padding: 0;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}