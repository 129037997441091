/*
  Variables
*/
:root {
  /* Colours */
  --color-black-brand: #000000;
  --color-blue-brand: #D7E9F7;
  --color-green-brand: #DAF7E8;
  --color-yellow-brand: #FFFEDA;
  --color-red-brand: #FFE1E1;
  --color-orange-brand: #F9E4C8;
  --color-purple-brand: #E3DBF1;

  --color-background: #F1F1F1;
  --color-border: #E9E9E9;
  --color-nav: #132D75;

  --color-muted: #B1B5BE;

  --color-yellow-bright: #FECF00; /* ScreenCloud brand yellow */
  --color-green-bright: #02C968; /* User status: active / success */
  --color-orange-bright: #FBA565; /* User status: pending / warning */
  --color-red-bright: #FA5252; /* User status: disabled / error */

  /* Fonts */
  --font-family-default: 'Lato', sans-serif;
  --font-size-default: 16px;
  --font-size-small: 14px;
  --font-size-large: 24px;
  --font-size-huge: 36px;

  /* Dimensions */
  --dimension-padding-default: 24px;
  --dimension-padding-medium: 18px;
  --dimension-logo-width: 47px;
  --dimension-header-height: 65px;
  --dimension-input-height: 50px;
}

/*
  Reset
*/
*, ::before, ::after { box-sizing: border-box }
ul[class], ol[class], fieldset { padding: 0 }
body, h1, h2, h3, h4, p, ul[class], ol[class], li, i, figure, figcaption, blockquote, dl, dd, fieldset, pre { margin: 0; font-weight: 400; font-style: normal; font-family: var(--font-family-default); }
body { min-height: 100vh; scroll-behavior: smooth; text-rendering: optimizeSpeed; line-height: 1.5 }
ul[class], ol[class] { list-style: none }
a:not([class]) { text-decoration-skip-ink: auto }
img { max-width:100%; border: 0; display: block }
input, button, textarea,select { font: inherit }
:focus, fieldset { outline:none }
fieldset { min-width: 0; border:0 }
body:not(:-moz-handler-blocked) fieldset { display: table-cell }
a, a:visited, a:visited:hover, a:active { text-decoration: none }
@media (prefers-reduced-motion: reduce) {*{ animation-duration: .01ms!important; animation-iteration-count: 1!important; transition-duration: .01ms!important; scroll-behavior: auto!important }}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus { box-shadow: none; -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important; }

/*
  Structure
*/
#root {
  height: 100vh
}
body {
  background: var(--color-background);
  font-size: var(--font-size-default);
  color: var(--color-black-brand);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::placeholder {
  color: rgba(0,0,0,.2);
}

/*
  Hyperlinks
*/
a:link,
a:visited,
a:hover,
a:active,
.link
{ 
  color: var(--color-nav);
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:visited:hover,
.link:hover,
.link:visited:hover
{
  text-decoration: underline;
}

/*
  Utils
*/
.center { text-align: center !important }
.muted { color: var(--color-muted) !important }
.bgWhite { background: #fff !important }

/*
  Phone portrait
*/
@media (max-width: 479px) {
  :root {
    --dimension-padding-default: 18px;
    --dimension-padding-medium: 12px;
  }
}