.stat {
  width: 100%;
  text-align: center;
}
.stat h1 {
  font-size: var(--font-size-huge);
  font-weight: 700;
}
.stat p {
  font-weight: 400;
}
.stat small {
  font-size: var(--font-size-small);
  color: var(--color-muted)
}