.container {
  width: 100%;
  border-bottom: solid 1px var(--color-border);
  display: flex;
}
.container:last-child {
  border: 0
}
.container:nth-child(odd) {
  background: #fff
}
.container:nth-child(even) {
  background: #f7f7f7
}