.container {
  width: calc(100% - (var(--dimension-padding-medium) * 2));
  max-width: 400px;
  padding: var(--dimension-padding-medium);
  color: #fff;
  box-shadow: 1px 1px 15px rgba(0,0,0,.1);
  right: var(--dimension-padding-medium);
  bottom: var(--dimension-padding-medium);
  position: fixed;
  z-index: 1000
}
.close {
  transition: opacity .1s linear;
  right: 4px;
  top: 4px;
  position: absolute;
  cursor: pointer;
  opacity: .6;
}
.close:hover {
  opacity: 1
}
.close :global(.icon) {
  line-height: normal;
}

/* 
  Types
*/
.error {
  background-color: var(--color-red-bright);
}
.success {
  background-color: var(--color-green-bright);
  font-weight: 700
}