.container {
  width: 100%;
  height: 110px;
  padding: var(--dimension-padding-medium);
  padding-left: calc(var(--dimension-padding-medium) - 8px);
  border: 0;
  border-left: solid 8px transparent;
  background: rgba(0,0,0,.06);
}
.container[disabled] {
  color: var(--color-muted);
  text-shadow: 1px 1px 1px #fff;
  cursor: not-allowed;
}
.container[required] {
  border-left-color: var(--color-blue-brand)
}