.container {
  width: 100%;
}

.select [class$="-control"] {
  height: var(--dimension-input-height);
  background: rgba(0,0,0,.06);
  border: 0;
  border-left: solid 8px transparent;
  box-shadow: none !important;
  border-radius: 0;
}
.select [class$="-ValueContainer"] {
  padding: 2px var(--dimension-padding-medium);
  padding-left: calc(var(--dimension-padding-medium) - 8px)
}
.select [class$="-placeholder"] {
  color: rgba(0,0,0,.2);
}

.required .select [class$="-control"] {
  border-left-color: var(--color-blue-brand);
}