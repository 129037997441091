.container {
  padding: var(--dimension-padding-medium);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--color-muted);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}
.container a {
  font-weight: 700;
}
