.container {
  width: 100%;
  padding: 13px 13px 13px 0;
  color: var(--color-muted);
  align-items: center;
  display: flex;
}

/*
  Phone portrait
*/
@media (max-width: 479px) {
  .container {
    padding: 8px 8px 13px 0;
  }
}