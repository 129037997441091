.container {
  width: 100%;
  height: 100%;
  padding: var(--dimension-padding-medium);
}

.slim {
  padding: 0
}

.blue {
  background-color: var(--color-blue-brand);
}
.green {
  background-color: var(--color-green-brand);
}
.yellow {
  background-color: var(--color-yellow-brand);
}
.red {
  background-color: var(--color-red-brand);
}
.orange {
  background-color: var(--color-orange-brand);
}
.purple {
  background-color: var(--color-purple-brand);
}
.white {
  background-color: #FFF;
}