.image, .missing {
  border-radius: 50%;
}
.missing {
  background-color: var(--color-blue-brand);
  color: var(--color-muted);
  font-weight: 700;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.large {
  width: 60px;
  height: 60px;
  font-size: 25px;
}
.small {
  width: var(--dimension-logo-width);
  height: var(--dimension-logo-width);
  font-size: 20px;
}