.container {
  height: var(--dimension-header-height);
  padding: 0 var(--dimension-padding-default);
  background: #fff;
  border-bottom: solid 1px var(--color-border);
  align-items: center;
  display: flex;
}

.logo, .user {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 200px;
}

.navigation {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  justify-content: center;
  display: flex;
}
.nav {
  transition: opacity .1s linear;
  margin: 0 var(--dimension-padding-default);
  font-weight: 400;
  color: var(--color-nav);
  text-decoration: none !important;
  opacity: .5;
}
.nav:first-child {
  margin-left: 0
}
.nav:last-child {
  margin-right: 0
}

.nav:hover, .nav.active {
  opacity: 1;
}

.user {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  position: relative;
}
.avatar {
  width: var(--dimension-logo-width);
  height: var(--dimension-logo-width);
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: var(--dimension-logo-width);
}
.name {
  margin: 0 8px;
  color: var(--color-nav);
  font-size: var(--font-size-small);
  font-weight: 700;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}
.logout {
  transition: opacity .1s linear;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
  opacity: .4;
}
.logout:hover {
  opacity: 1;
}
.logout :global(.icon) {
  color: var(--color-nav);
}

/*
  Tablet portrait
*/
@media (max-width: 1023px) {
  .logo, .user {
    flex-basis: var(--dimension-logo-width);
  }
  .name {
    display: none
  }
  .logout {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    border-radius: 50%;
    text-decoration: none !important;
    left:0;
    top: 0;
    position: absolute;
    z-index: 10;
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: 1;
  }
  .logout :global(.icon) {
    color: #fff
  }
}
