.container {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.8);
  left: 0;
  top: 0;
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 500;
  overflow: auto;
  overflow-x: hidden
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .container {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.inner {
  width: 100%;
  max-width: 600px;
  padding: var(--dimension-padding-medium);
  color: #fff;
  text-align: center
}
.inner h1 {
  margin: 0 0 12px;
  font-size: var(--font-size-huge);
  font-weight: 300;
}
.inner p {
  margin: 0 0 24px;
}
.inner p:last-child {
  margin: 0
}

.inner :global(.form__input),
.inner :global(.form__textarea),
.inner :global(.form__dropdown)
{
  background: #fff
}
