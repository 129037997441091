.container {
  height: calc(var(--dimension-input-height) - 4px);
  margin-right: 24px;
  padding: 0 var(--dimension-padding-default);
  background: var(--color-yellow-bright);
  color: var(--color-black-brand) !important;
  border: 0;
  border-top: solid 4px transparent;
  border-bottom: solid 4px transparent;
  font-weight: 400;
  cursor: pointer;
}
.container:hover {
  border-bottom-color: rgba(0,0,0,.1)
}
.container:last-child {
  margin-right: 0;
}

.red {
  background: var(--color-red-bright);
  color: #fff !important
}

.container[disabled] {
  background: #ddd;
  border-color: #ddd !important;
  color: var(--color-muted) !important;
  cursor: not-allowed
}

/*
  Phone portrait
*/
@media (max-width: 479px) {
  .container {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }
}