.notice {
  width: 100%;
  padding: 100px 0;
  text-align: center;
  color: var(--color-muted);
  font-weight: 300;
  font-size: var(--font-size-huge);
  text-shadow: 1px 1px 0 #fff;
}

/*
  Tablet portrait
*/
@media (max-width: 1023px) {
  .row :global(.listing__column):nth-child(1) {
    width: 100% !important;
    flex-basis: 0 !important;
  }
  .row :global(.listing__column):nth-child(n+2) {
    display: none
  } 
}

