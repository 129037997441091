.container {
  margin: 0 calc(var(--dimension-padding-default) / 2);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

/*
  Phone landscape
*/
@media (max-width: 812px) {
  .container {
    margin-bottom: var(--dimension-padding-default);
  }
  .container:last-child {
    margin-bottom: 0
  }
}