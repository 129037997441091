.container {
  margin: 0 calc((var(--dimension-padding-default) / 2) * -1);
  display: flex;
}

/*
  Phone landscape
*/
@media (max-width: 812px) {
  .container {
    flex-direction: column;
  }
}